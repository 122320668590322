<template>
    <div class="tab-switcher">
        <div class="section-title" v-if="showTitle">
            <div class="button-container" v-if="hasArrowLeft" @click="$emit('arrowLeft')">
                <button class="btn -icon-only">
                    <icon-left />
                </button>
            </div>
            <div class="title-container">
                <h3>{{ $t(title) }}</h3>
            </div>
            <div class="button-container" v-if="hasArrowRight" @click="$emit('arrowRight')">
                <button class="btn -icon-only">
                    <icon-right />
                </button>
            </div>
        </div>
        <div class="section-tabs">
            <ul>
                <li v-for="(tab, index) in items"
                    class="tab"
                    :class="{ 'active': tab === activeItem }"
                    :key="index"
                    @click.prevent="$emit('change', tab)">
                    {{ $t(tab) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import iconRight from "@/assets/icons/icon-arrow-right.svg?inline";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    props: {
        showTitle: Boolean,
        title: String,
        items: Array,
        activeItem: [ String, Number ],
    },
    name: "TabSwitcher",
    components: {
        iconLeft,
        iconRight,
    },
    computed: {
        hasArrowLeft(){
            return (this.$attrs && this.$attrs.onArrowLeft);
        },
        hasArrowRight(){
            return (this.$attrs && this.$attrs.onArrowRight);
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
