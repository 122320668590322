<template>
    <div
        class="fab"
        :class="{ active: isActive }">
        <button
            class="btn -icon"
            @click="isActive = !isActive">
            {{ title }}
            <slot name="fab-button-icon" v-if="!isActive"></slot>
            <iconClose v-if="isActive" />
        </button>
        <div class="fab-list">
            <slot name="fab-list"></slot>
        </div>
    </div>
</template>

<script>
import iconClose from "@/assets/icons/icon-close.svg?inline";

export default {
    props: {
        title: String,
    },
    name: "Fab",
    components: {
        iconClose,
    },
    data() {
        return {
            isActive: false
        };
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
