<template>
    <div class="container view">
        <section>
            <strong>Header theme</strong>
            <Header
                title="Mijn project gezond"
                subtitle="Woensdag 5 jan">
                <template v-slot:button-start>
                    <button @click="$router.go(-1)" class="btn -icon-only">
                        <iconBack />
                    </button>
                </template>
                <template v-slot:button-end>
                    <button class="btn -icon-only">
                        <iconHeart />
                    </button>
                </template>
            </Header>
        </section>

        <section>
            <strong>Header transparent</strong>
            <Header
                title="Recepten"
                subtitle="Al het lekkers op een rij"
                theme="transparent">
                <template v-slot:button-end>
                    <button class="btn -icon-only">
                        <iconSetting />
                    </button>
                </template>
            </Header>
        </section>

        <section>
            <strong>Tab Switcher:</strong>
            {{ tabSwitcherActiveIndex }},{{ tabSwitcherTwoActiveIndex }}

            <tab-switcher
                @arrowLeft="() => {
                tabSwitcherActiveIndex--;

                if (tabSwitcherActiveIndex < 0) {
                    tabSwitcherActiveIndex = 6;
                }
            }"
                @arrowRight="() => {
                tabSwitcherActiveIndex++;

                if (tabSwitcherActiveIndex > 6) {
                    tabSwitcherActiveIndex = 0;
                }
            }"
                @change="(tab) => {
                tabSwitcherActiveIndex = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'].indexOf(tab);
            }"
                :show-title="true"
                title="Weekmenu"
                :items="['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']"
                :active-item="['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'][tabSwitcherActiveIndex]"
            />

            <tab-switcher
                :style="{ marginTop: '20px' }"
                @change="(tab) => {
                tabSwitcherTwoActiveIndex = ['1250 kcal', '1500 kcal', '1750 kcal', '2000 kcal'].indexOf(tab);
            }"
                :items="['1250 kcal', '1500 kcal', '1750 kcal', '2000 kcal']"
                :active-item="['1250 kcal', '1500 kcal', '1750 kcal', '2000 kcal'][tabSwitcherTwoActiveIndex]"
            />
        </section>
        <section>
            <strong>Slider</strong>
            <Slider
                :navigation="true"
                :responsive="true"
                :space-between="10"
                :pagination="false"
                :stepper="false">
                <template
                    v-for="(item, index) in sliderItems"
                    v-slot:[`slide-${index}`]
                    :key="index">
                    <p>{{ item }}</p>
                </template>
            </Slider>
            <Slider
                :navigation="true"
                :responsive="false"
                :space-between="10"
                :pagination="true"
                :stepper="false">
                <template
                    v-for="(item, index) in sliderItems"
                    v-slot:[`slide-${index}`]
                    :key="index">
                    <label>Tip {{ index + 1 }}</label>
                    <hr />
                    <p>{{ item }}</p>
                </template>
            </Slider>
            <Slider
                :navigation="true"
                :responsive="false"
                :space-between="10"
                :pagination="false"
                :stepper="false">
                <template v-slot:[`slide-1`]>
                    Statische slide 1
                </template>
                <template v-slot:[`slide-2`]>
                    Statische slide 2
                </template>
            </Slider>
        </section>
        <section>
            <strong>Modal:</strong>
            <button @click="showModal = true">Open modal</button>
            <div v-if="showModal">
                <Modal
                    title="Filteren"
                    subtitle="Vind een specifiek recept"
                    @close="showModal = false">
                    <template v-slot:modal-header-button-start>
                        <button @click="showModal = false" class="btn -icon-only">
                            <iconLeft />
                        </button>
                    </template>
                    <template v-slot:modal-header-button-end>
                        <button class="btn -icon-only">
                            <iconHeart />
                        </button>
                    </template>
                    Content
                </Modal>
            </div>
        </section>
        <section>
            <strong>Toast Notifications:</strong>
            <button @click="$toast({ message: 'Dit is onze lange default toast!', duration: 5000 })">Default Toast</button>
            <button @click="$toast({ type: 'success', message: 'Dit is onze korte success toast!' })">Success Toast</button>
            <button @click="$toast({ type: 'error', message: 'Dit is onze korte error toast!' })">Error Toast</button>
        </section>
        <section>
            <strong>Typo</strong>
            <h1>H1 voorbeeld</h1>
            <h2>H2 voorbeeld</h2>
            <h3>H3 voorbeeld</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac turpis quis magna rhoncus convallis sit amet eu ipsum. Maecenas egestas tempor nunc, ac vulputate arcu sagittis a. Sed facilisis odio eros, quis tincidunt turpis mattis vel. Etiam sed viverra tellus. Aliquam suscipit pharetra purus in lacinia. Suspendisse dignissim congue scelerisque. Curabitur rhoncus risus sed orci fermentum rutrum. Phasellus varius eros vitae lectus feugiat, non condimentum felis gravida. Fusce commodo rhoncus leo, sit amet ultricies tellus. Maecenas semper tempus nulla, vel laoreet est porta nec.
            </p>
        </section>
        <section>
            <strong>Buttons</strong>
            <button class="btn -icon"><iconHeart />Opslaan als favoriet dagmenu</button> <hr />
            <button class="btn -icon">PDF <iconHeart /></button> <hr />
            <button class="btn -theme -icon"><iconHeart />Reactie plaatsen</button> <hr />
            <button class="btn -theme -lg">Reactie plaatsen</button> <hr />
            <button class="btn -accent">Reactie plaatsen</button> <hr />
            <button class="btn -outline -white">Reactie plaatsen</button>
            <button class="btn -outline -accent">Reactie plaatsen</button>
            <button class="btn -outline -theme">Reactie plaatsen</button>
            <button class="btn -link">Reactie plaatsen</button> <hr />
        </section>
        <section>
            <strong>Fab</strong>
            <Fab
                title="Menus bij dit recept">
                <template v-slot:fab-button-icon>
                    <iconLeft />
                </template>
                <template v-slot:fab-list>
                    <ul>
                        <li>optie 1</li>
                        <li>optie 1</li>
                        <li>optie 1</li>
                    </ul>
                </template>
            </Fab>
            <Fab
                title="Sorteren">
                <template v-slot:fab-button-icon>
                    <iconRight />
                </template>
                <template v-slot:fab-list>
                    <ul>
                        <li>optie 1</li>
                        <li>optie 1</li>
                    </ul>
                </template>
            </Fab>
        </section>
        <section>
            <strong>Formulieren</strong>
            <label class="label">Input text</label>
            <input type="text" placeholder="Vul je voornaam in">
            <label>Input radio</label>
            <input type="radio">
            <label>Input checkbox</label>
            <input type="checkbox">
            <label>Input date</label>
            <input type="date">
            <label>Input email</label>
            <input type="email">
            <label>Input password</label>
            <input type="password">
            <label>Input number</label>
            <input type="number">
            <label>Input search</label>
            <input type="search">
            <label>Input tel</label>
            <input type="tel">
            <label class="label">Text area</label>
            <textarea placeholder="Schrijf hier je reactie op dit recept"></textarea>
        </section>
    </div>
</template>

<script>
import Modal from "@/components/Modal";
import Header from "@/components/Header";
import TabSwitcher from "@/components/Tabswitcher";
import Fab from "@/components/Fab";
import Slider from "@/components/Slider";

// Icons
import iconBack from "@/assets/icons/icon-chevron-left.svg?inline";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconRight from "@/assets/icons/icon-arrow-right.svg?inline";
import iconHeart from "@/assets/icons/icon-heart.svg?inline";
import iconSetting from "@/assets/icons/icon-settings.svg?inline";

export default {
    components: {
        Header,
        Modal,
        iconBack,
        iconLeft,
        iconRight,
        iconSetting,
        iconHeart,
        TabSwitcher,
        Fab,
        Slider
    },
    name: "TempComponents",
    data() {
        return {
            tabSwitcherActiveIndex: 0,
            tabSwitcherTwoActiveIndex: 0,
            showModal: false,
            sliderItems: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"]
        };
    }
};
</script>

<style scoped lang="scss">
    .view {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    strong {
        display: block;
        background: #000;
        color: #fff;
        margin: 16px 0;
        text-align: center;
        padding: 10px 0;
    }

    section {
        margin-top: 80px;
        margin-bottom: 80px;
    }
</style>
